import * as Sentry from '@sentry/gatsby';
import { settings } from './config/index.mjs';

if (settings.SENTRY_DSN) {
  Sentry.init({
    dsn: settings.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: `${settings.COMPANY_CODE}-${settings.ENV}`,
    beforeSend: (event, hint) => {
      if (settings.ENV != 'production') {
        // Logging when in qa or dev so we can see what errors to ignore
        console.log('event: ', event);
        console.log('hint: ', hint);
      }
      if (hint?.originalException?.stack?.includes('amplify.outbrain.com/cp/obtp.js') || hint?.originalException?.stack?.includes('https://www.googletagmanager.com/gtm.js')) {
        return undefined;
      }

      console.log('LOGGING ERROR TO SENTRY');
      return event;
    },
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', ''],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
